<template>
  <div>
    <div class="modal" tabindex="-1" role="dialog" id="modal--add-ws">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{
              isEdit
              ? $t("lbl_edit_workspace_info")
              : $t("workspace_setting.btn_create_ws")[$orgModel]
              }}
            </h5>
            <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row mt-2">
              <div class="col-sm-12">
                <div style="padding: 15px 15px">
                  <div class="row">
                    <div class="form-group mb-3">
                      <div class="row">
                        <div class="col-sm-6">
                          <label class="robo-20-500 mb-1 txt-grey-900 required">
                            {{
                            $t("workspace_setting.lbl_workspace_name")[
                            $orgModel
                            ]
                            }}
                          </label>
                        </div>
                        <input
                          class="border-0 bg-blue-gray txt-grey-700 placeholder-gray-400 h-10 rounded-md ml-2 focus:outline-none"
                          :placeholder="
                            $t('workspace_setting.lbl_ws_name_plh')[$orgModel]
                          "
                          v-model="data.name"
                        />
                      </div>
                      <small
                        v-if="isErrorValid && errorFields.name"
                        class="text-warning"
                      >{{ errorFields.name }}</small>
                    </div>
                    <div class="form-group mb-3">
                      <div class="row">
                        <div class="col-sm-6">
                          <label
                            class="robo-20-500 mb-1 txt-grey-900 required"
                          >{{ $t("workspace_setting.lbl_org_name") }}</label>
                        </div>
                        <!-- <div class="bg-blue-gray txt-grey-700 fw-600 h-10 rounded-md ml-2 p-2.5">{{org.name}}</div> -->
                        <div class="bg-blue-gray txt-grey-700 fw-600 rounded-md ml-2 p-0 hodo-el">
                          <el-select
                            class="w-full"
                            v-model="data.org_id"
                            default-first-option
                            clearable
                            filterable
                            remote
                            reserve-keyword
                            :disabled="isSubOrg"
                            :remote-method="handleFilterSearchSubOrg"
                            :loading="loading"
                            :placeholder="$t('workspace_setting.lbl_org_name')"
                            @change="handleResetFilterSearchSubOrg"
                          >
                            <el-option
                              v-for="item in orgs"
                              :key="item.id"
                              :label="item.suborg_name || item.name"
                              :value="item.id"
                            ></el-option>
                          </el-select>
                        </div>
                      </div>
                      <small
                        v-if="isErrorValid && errorFields.org_id"
                        class="text-warning"
                      >{{ errorFields.org_id }}</small>
                    </div>
                    <div class="form-group mb-3">
                      <div class="row">
                        <div class="col-sm-6">
                          <label
                            class="robo-20-500 mb-1 txt-grey-900 required"
                          >{{ $t("workspace_setting.lbl_address") }}</label>
                        </div>
                        <input
                          class="border-0 bg-blue-gray txt-grey-700 placeholder-gray-400 h-10 rounded-md ml-2 focus:outline-none"
                          :placeholder="$t('workspace_setting.lbl_address_plh')"
                          v-model="data.address"
                        />
                      </div>
                      <small
                        v-if="isErrorValid && errorFields.address"
                        class="text-warning"
                      >{{ errorFields.address }}</small>
                    </div>

                    <div class="form-group mb-3">
                      <div class="row">
                        <div class="col-sm-6">
                          <label class="robo-20-500 mb-1 txt-grey-900">
                            {{
                            $t("workspace_setting.lbl_description")
                            }}
                          </label>
                        </div>
                        <textarea
                          class="border-0 bg-blue-gray txt-grey-700 placeholder-gray-400 rounded-md ml-2 p-2.5 focus:outline-none"
                          :placeholder="$t('workspace_setting.lbl_desc_plh')"
                          rows="4"
                          cols="30"
                          v-model="data.desc_full"
                        />
                      </div>
                    </div>

                    <div class="form-group">
                      <div class="row">
                        <div class="col-sm-6">
                          <label
                            class="robo-20-500 mb-1 txt-grey-900 required"
                          >{{ $t('lbl_director_president') }}</label>
                        </div>
                        <input
                          class="border-0 bg-blue-gray placeholder-gray-400 h-10 rounded-md ml-2 focus:outline-none"
                          placeholder="hodo@gmail.com"
                          type="email"
                          v-model="data.email"
                          :disabled="isEdit"
                          :class="
                            isEdit
                              ? 'text-black cursor-not-allowed'
                              : 'txt-grey-700'
                          "
                        />
                      </div>
                      <small
                        v-if="isErrorValid && errorFields.email"
                        class="text-warning"
                      >{{ errorFields.email }}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-3 mx-10 d-flex align-items-center" v-if="!isEdit">
            <label class="mb-0">
              <input type="checkbox" v-model="accepted" class="mr-2" id="at" />
              <span>
                {{ $t("workspace_setting.lbl_agree_msg") }}
                <a
                  href="/HODOServicesAgreementWS/HODOWorkspaceServiceAgreementWorkspace.pdf"
                  target="_blank"
                >{{ $t("workspace_setting.lbl_agrrement")[$orgModel] }}</a>
              </span>
            </label>
          </div>
          <div class="modal-footer text-right">
            <button
              :disabled="isEdit ? is_submit : is_submit || !accepted"
              class="btn-action btn bg-pri bd-pri text-white"
              @click="onSubmit"
            >
              <span class="spinner-border spinner-border-sm mr-2" role="status" v-if="is_submit"></span>
              <span>
                {{
                isEdit
                ? $t("button.save")
                : $t("workspace_setting.btn_create_ws")[$orgModel]
                }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .hodo-el .el-input__inner {
    background: transparent !important;
    border: 1px solid transparent !important;
    font-weight: 400 !important;
    padding-left: 8px !important;

    &::placeholder {
      color: #9ca3af !important;
    }
  }
}
</style>

<script>
import { mixinValidationForms } from '../../utils/mixinValidationForms'

export default {
  name: 'ModalAddWS',
  props: {
    org: Object,
    actionCallModal: Number,
    wsDetail: Object
  },
  mixins: [mixinValidationForms],
  data () {
    const initialForm = {
      name: '',
      org_id: '',
      address: '',
      desc_full: '',
      email: ''
    }

    return {
      orgData: this.org || {},
      loading: false,
      is_submit: false,
      initialForm,
      data: { ...initialForm },
      ignoreFields: ['desc_full'],
      list: [],
      metaList: {},
      orgs: [],
      accepted: false,
      page: 1,
      isEdit: false
    }
  },
  async created () {
    await this.getSubOrg()
  },
  beforeMount () {
    this.handleResetErrors(this.data, this.errorFields, this.ignoreFields)
  },
  mounted () {
    const self = this
    window.$('#modal--add-ws').on('hide.bs.modal', function (e) {
      self.data = { ...self.initialForm }
      self.list = [self.org]
      self.isEdit = false
      self.$emit('resetViewDetailWs')
    })
  },
  watch: {
    org (data) {
      if (data) {
        this.list = [this.org, ...this.list]
      }
    },
    actionCallModal (count) {
      this.orgData = this.org
      this.getSubOrg()
      this.isEdit = false
    },
    list (data) {
      this.orgs = [...data]
    },
    wsDetail (data) {
      if (data) {
        this.isEdit = true
        this.data = {
          name: data?.name || '',
          org_id: data?.org_id || '',
          address: data?.address || data?.organization?.address || '',
          desc_full: data?.descFull || '',
          email: data?.email || ''
        }
      } else {
        this.isEdit = false
        this.data = { ...this.initialForm }
      }
    }
  },
  computed: {
    noMore () {
      return this.metaList?.current_page >= this.metaList?.last_page
    },
    disabled () {
      return this.loading || this.noMore
    },
    isSubOrg () {
      if (this.org?.parent_id) {
        this.data.org_id = this.org.id
        return true
      } else {
        return false
      }
    }
  },

  methods: {
    async getSubOrg () {
      const self = this
      if (!self.orgData?.id) return

      try {
        const params = {
          page: self.page,
          limit: 1000
        }

        await self.$rf
          .getRequest('DoctorRequest')
          .getSubOrganization(self.orgData?.id, params)
          .then(res => {
            if (res.data?.data && res.data?.data?.length) {
              self.list = [...self.list, ...res.data?.data]
              self.meta = {
                ...res.data?.meta
              }
            }
          })
      } catch (error) {
        console.log(error)
      }
    },
    async onSubmit () {
      const self = this

      if (!self.accepted && !self.isEdit) {
        alert(self.$t('workspace_setting.lbl_agree_required'))
        return
      }

      self.handleResetErrors(self.data, self.errorFields, self.ignoreFields)
      self.is_submit = true

      try {
        self.isErrorValid = self.handleValidationForms(
          self.data,
          self.errorFields,
          self.ignoreFields
        )

        if (self.isErrorValid) {
          return
        }

        if (self.isEdit) {
          if (!self.wsDetail?.id) {
            self.$message({
              type: 'error',
              message: this.$t('Không tìm thấy thông tin Khoa/ Trung tâm')
            })
            return
          }

          const org = self.orgs.find(item => item.id === self.data.org_id)
          const params = {
            ...self.data,
            org_name: org.name || org.suborg_name
          }

          await self.$rf
            .getRequest('DoctorRequest')
            .postOrgClinicEdit(self.wsDetail?.id, params)
            .then(res => {
              self.$reloadChat++
              self.$emit('reload')
              self.data = { ...self.initialForm }
              self.isEdit = false
              self.$emit('resetViewDetailWs')
            })
            .finally(() => window.$('#modal--add-ws').modal('hide'))
        } else {
          await self.$rf
            .getRequest('DoctorRequest')
            .postWorkSpaces(self.data)
            .then(res => {
              self.$reloadChat++
              self.accepted = false
              self.$emit('reload')
              self.data = { ...self.initialForm }
            })
            .finally(() => window.$('#modal--add-ws').modal('hide'))
        }
      } catch (e) {
        console.log(e)
        self.is_submit = false
      } finally {
        self.is_submit = false
      }
    },
    handleFilterSearchSubOrg (query) {
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          this.orgs = this.list.filter(item => {
            return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
          })
        }, 200)
      } else {
        this.orgs = this.list
      }
    },
    handleResetFilterSearchSubOrg () {
      this.orgs = this.list
    }
  }
}
</script>
