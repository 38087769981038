<template>
  <div>
    <div class="table-responsive">
      <div class="bg-white rounded-3xl p-3">
        <div class="flex items-center justify-end">
          <ButtonHoDo
            :title="$t(`workspace_setting.lbl_add_workspace`)[$orgModel]"
            class-name="bg-blue-700 text-white rounded-lg h-9 px-3 py-2"
            @click="onShowModalAddWS(true)"
          />
        </div>
        <div class="table-responsive">
          <div class>
            <div
              class="fs-20 fw-600 grid grid-cols-8 gap-4 p-6 border-bottom border-gray-700 mb-6 pb-3"
            >
              <div
                class="txt-grey-1000 col-span-2"
              >{{ $t("workspace_setting.lbl_we_name")[$orgModel] }}</div>
              <div class="txt-grey-1000 col-span-2">{{ $t('lbl_org') }}</div>
              <div class="txt-grey-1000 col-span-2">{{ $t('lbl_director_president') }}</div>
              <div class="txt-grey-1000 col-span-1">{{ $t('lbl_total_staffs') }}</div>
              <div class="txt-grey-1000 col-span-1">&nbsp;</div>
            </div>
            <div v-if="currentWS.length">
              <div
                v-for="(ws, index) in currentWS"
                :key="index"
                class="cursor-pointer mb-2 radius-10 bg-blue-gray grid grid-cols-8 gap-4 p-6 fs-16 fw-400 txt-grey-900"
                @click="recordViewDetail(ws)"
              >
                <div class="fw-700 col-span-2">
                  <div>{{ ws && ws.name }}</div>
                  <div class="fs-14 fw-400 mt-1">{{ ws.address }}</div>
                </div>
                <div class="col-span-2">
                  <div>{{ ws && ws.organization && ws.organization.name }}</div>
                </div>
                <div class="col-span-2">
                  <div>{{ ws && ws.doctor && ws.doctor.User && ws.doctor.User.name }}</div>
                </div>
                <div class="col-span-1 flex items-center">
                  <div>{{ ws.number_member }}</div>
                </div>
                <div class="col-span-1 flex items-center justify-end" @click.stop>
                  <div class="h-7 w-7 mx-2" @click="viewDetailWs(ws)">
                    <i class="el-icon-edit-outline text-xl text-blue-900 cursor-pointer" />
                  </div>
                  <!-- <div class="h-7 w-7 mx-2" @click="deleteWs(ws)">
                      <Delete class="cursor-pointer" />
                  </div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex mt-3 flex-col md:flex-row justify-between items-center gap-2">
          <div class="fs-16">{{ $t('fm_pagination',{countItems,totalItems:totalItems }) }}</div>
          <el-pagination
            background
            layout="prev, pager, next"
            :pager-count="5"
            :page-size="DEFAULT_SIZE_OF_PAGE"
            :total="totalItems"
            class="el-pagination-mdt"
            @next-click="handleChangePage"
            @prev-click="handleChangePage"
            @current-change="handleChangePage"
          ></el-pagination>
        </div>
      </div>
    </div>

    <ModalAddWS
      :org="org"
      :wsDetail="wsDetail"
      :actionCallModal="actionCallModal"
      @reload="callAPI"
      @resetViewDetailWs="resetViewDetailWs"
    />
  </div>
</template>

<script>
import { ButtonHoDo } from '@/components/Button'
import ModalAddWS from '../pages/Organization/ModalAddWS.vue'
import appUtils from '../utils/appUtils'
import { DEFAULT_SIZE_OF_PAGE } from '../utils/constants'
import { debounce } from 'lodash'
import { Delete } from '@/components/Icon'

export default {
  name: 'OrganizationTableWS',
  components: { ButtonHoDo, ModalAddWS, Delete },
  props: ['orgId'],
  data () {
    return {
      DEFAULT_SIZE_OF_PAGE,
      workspaces: [],
      currentWS: [],
      org: {},
      dataOrgMember: null,
      appUtils,
      totalItems: 0,
      page: 1,
      actionCallModal: 0,
      wsDetail: {}
    }
  },
  mounted () {
    this.callAPI()
  },
  computed: {
    countItems () {
      return this.page * DEFAULT_SIZE_OF_PAGE > this.totalItems
        ? this.totalItems
        : this.page * DEFAULT_SIZE_OF_PAGE
    }
  },
  watch: {
    workspaces (data) {
      if (data) {
        this.currentWS = data.slice(0, DEFAULT_SIZE_OF_PAGE)
      }
    },
    page (currPage) {
      this.currentWS = this.workspaces.slice(
        (currPage - 1) * DEFAULT_SIZE_OF_PAGE,
        currPage * DEFAULT_SIZE_OF_PAGE
      )
    }
  },
  methods: {
    callAPI () {
      this.getOrg(this.orgId)
      this.getWorkSpacesAllV2(this.orgId)
      this.getOrgMembersV2()
    },
    async getWorkSpacesAllV2 (id) {
      if (!id) return
      const res = await this.$rf
        .getRequest('DoctorRequest')
        .getWorkSpacesAllV2(id)
      this.workspaces = res.data?.reverse()
      this.$store.commit('setListWS', { listWS: res.data })
      this.totalItems = res.data?.length
    },
    async getOrg (id) {
      if (!id) return
      const res = await this.$rf.getRequest('DoctorRequest').getOrg(id)
      this.org = res.data
    },
    onShowModalAddWS (show) {
      if (!this.checkRoleOwnerOrg(this.org)) {
        alert('Bạn không có quyền thêm khoa/trung tâm trong Org này')
        return
      }
      this.actionCallModal++
      window.$('#modal--add-ws').modal(show ? 'show' : 'hide')
    },
    async getOrgMembersV2 () {
      if (!this.orgId) return
      try {
        const params = {
          orgId: this.orgId,
          status: 1,
          limit: 1000,
          page: 1
        }
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getOrgMembersV2(params)

        this.dataOrgMember = response.data?.data || []
      } catch (error) {
        console.log(error)
      }
    },
    handleChangePage: debounce(function (newPage) {
      this.page = newPage
    }, 500),
    async recordViewDetail (record) {
      if (!record) return

      this.$router
        .push({
          path: `/organization/workspace/${record?.id}`
        })
        .catch(_ => {})
    },
    viewDetailWs (record) {
      if (!record) return
      this.wsDetail = record
      this.onShowModalAddWS(true)
    },
    resetViewDetailWs () {
      this.wsDetail = {}
    },
    async deleteWs (ws) {
      if (!this.checkRoleOwnerOrg(this.org || this.$user)) {
        alert('Bạn không có quyền xóa nhân sự trong Org này')
        return
      }
      if (confirm(`Bạn muốn xóa ${ws.name}`)) {
        await this.deleteOrgMembers(ws.id)
        await this.getWorkSpacesAllV2(this.orgId)
      }
    }
  }
}
</script>

  <style lang="scss" scoped>
::v-deep {
  .txt-grey-1000 {
    color: #52575c !important;
  }

  .el-pagination-mdt {
    padding: 0;

    & .number.active {
      background-color: #20419b !important;
    }
  }
}
</style>
